import React from "react"
import { Link, graphql } from "gatsby"
import type { PageProps } from "gatsby"

import parse from "html-react-parser"

import Layout from "../components/UI/Layout"
import Seo from "../components/UI/Seo"
import Bio from "../components/Content/Bio"

interface BlogPostProps {
  nextPagePath: string,
  previousPagePath: string,
  postsPerPage: number;
}
type BlogIndexPageProps = PageProps<GatsbyTypes.WordPressPostArchiveQuery, BlogPostProps>

const BlogIndex = ({
  data,
  pageContext,
}: BlogIndexPageProps) => {
  const posts = data.allWpPost.nodes
  const { nextPagePath, previousPagePath, postsPerPage } = pageContext

  if (!posts.length) {
    return (
      <Layout pageTitle="News">
        <Seo title="News" description="News Archive" />
        <Bio />
        <p>
          No blog posts found. Add posts to your WordPress site and they'll
          appear here!
        </p>
      </Layout>
    )
  }

  return (
    <Layout pageTitle="News">
      <Seo title="News" description="News Archive" />

      <div className="text-left mb-36">
        <ol>
          {posts.map(post => {
            return (
              <li key={post.id!}>
                <article
                  className="post-list-item"
                  itemScope
                  itemType="http://schema.org/Article"
                >
                  <header>
                    <h2 className="font-bold text-lg mb-4">
                      <Link to={post.uri!} itemProp="url">
                        <span className="tracking-wide text-gray-700 not-italic"
                          itemProp="headline">{parse(post.title!)}</span>
                      </Link>
                    </h2>
                  </header>
                  <section>{parse(post.content!)}</section>
                  <small className="italic">Posted on {post.date}</small>
                  <hr className="block mt-5 mb-10 w-16 border-black" />
                </article>
              </li>
            )
          })}
        </ol>

        {previousPagePath && (
          <>
            <Link to={previousPagePath}>Previous page</Link>
            <br />
          </>
        )}
        {nextPagePath && <Link to={nextPagePath}>Next page</Link>}
      </div>

    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query WordPressPostArchive($offset: Int!, $postsPerPage: Int!) {
    allWpPost(
      sort: { fields: [date], order: DESC }
      limit: $postsPerPage
      skip: $offset
    ) {
      nodes {
        id
        content
        uri
        date(formatString: "MMMM DD, YYYY")
        title
      }
    }
  }
`
